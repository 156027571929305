.section-hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}
.section-hero-content .section-hero-links {
    visibility: hidden;
    display: none;
}

.section-hero {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/fish-background-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.section-hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-color: rgba(27, 67, 197, 0.555);
    box-shadow: inset 200px 350px 700px #051a462a,inset -200px -350px 700px #08105c3a;;
}

#menu-icon {
    margin: 0 auto;
    color: white;
}
.hero-text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    
    z-index: 2;
    position: relative;
    width: 75%;
}

@media (min-width: 700px){
    .section-hero-content .section-hero-links {
        visibility: visible;
        display: flex;
    }
  .section-hero-content .section-hero-links li {
        list-style-type: none;
    }
    
    #menu-icon {
        visibility: hidden;
    }

}
