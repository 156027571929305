* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body, html {
    font: 400;
    scroll-behavior: smooth;
}

:root{
    font-size: 60%;

    --color-background: #ffffff;
    --color-behind-background: #F0F0F0 ;
    --main-color: #FF0000 ;
    --soft-main-color: #F05E5E ;
    --secondary-color: #F05E5E ;
    --color-button: #524d4d ;
    --color-title: #524d4d ;
    --color-text: black ;

}

body, input, button, textarea{
    font: 400 1.6rem Roboto;
    color: var(--color-base);
}

.container{
    width: 95vw;
    max-width: 700px;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
    color: var(--color-base);
}

@media (min-width: 700px){
    :root{
        font-size: 65%;
    }

}